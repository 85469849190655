body[data-theme="material-purple-light"] {
  .sidebar {
    --cui-sidebar-bg: #9c27b0 !important;
  }
  --cui-primary-rgb: 156, 39, 176 !important;
  h3,
  .h3 {
    font-size: 1.25rem !important;
  }
  .use-my-location-button {
    line-height: 1.2858;
    font-size: 0.6rem;
  }
}
