body[data-theme="material-blue-light"] {
  .sidebar {
    --cui-sidebar-bg: #0590cf !important;
  }
  h3,
  .h3 {
    font-size: 1.25rem !important;
  }
  .use-my-location-button
  {
    line-height: 1.2858;
    font-size: 0.6rem;
  }
  --cui-primary-rgb: 3, 169, 244 !important;
}
