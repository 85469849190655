body[data-theme='material-orange-light'] {
    .sidebar {
      --cui-sidebar-bg: #ff5722 !important;
      --cui-sidebar-nav-link-active-bg: #d75931 !important;
    }
    h3,
    .h3 {
      font-size: 1.25rem !important;
    }
    --cui-primary-rgb:255, 87, 34 !important; 
    .use-my-location-button
  {
    line-height: 1.2858;
    font-size: 0.6rem;
  }
  }