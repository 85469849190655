#change-password .change-password-info {
  display: flex;
  flex-direction: column;
  color: #333;
  line-height: 36px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
#change-password .change-password-success-form {
    width: 100%;
    border-radius: 3px;
    border: solid 1px #e6e6e6;
    background-color: #fff;
    padding: 1rem;
    margin-top: 30px;
    text-align: left;
    color: #333;
    font-size: 14px;
}

.change-password-sucess-icon
{
    margin-right: 10px;
    color: green;
    font-size: 18px;
    text-align: center;
}

#change-password .change-password-info .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

#change-password .change-password-success-form p
{
    font-weight: 600;
}
