$sidebar-width: 14rem !important;
.dx-datagrid-header-panel {
  padding: 10px;
}
.breadcrumb-item {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
  font-size: 14px;
}

.app-form .form-body {
  height: calc(100vh - 200px);
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.list-grid {
  height: calc(100vh - 170px);
  overflow: auto;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.app-form .card-header {
  font-size: 1.75rem;
  background: none;
  font-weight: 500;
  color: black;
}

.swal2-popup {
  font-size: 0.9rem !important;
}

.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.login-container {
  height: 100vh;
}
.login-image {
  background-image: url("../assets/login-bg.jpg");
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: cover;
}
.ui-bg-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: #343a40 !important;
  opacity: 0.5 !important;
}

.login-text {
  position: absolute;
  left: 5%;
  top: 40%;
  color: white;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.text-large {
  font-size: 150% !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.login-info {
  font-size: 1.5rem;
  color: rgb(114 119 124 / 68%) !important;
}

label sup {
  color: red;
}
.app-buttton .dx-button-content {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: baseline !important;
}
.form-control:focus {
  box-shadow: none;
}
.bread-crumb-container nav {
  margin-left: 10px;
}

.dx-datagrid-headers {
  color: #000000;
  font-weight: 400;
  background-color: #fafafa !important;
}
.dx-button {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.panel-container {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.panel-container .card-header {
  width: 100%;
}

.sidebar-narrow-unfoldable .nav-item a {
  padding-left: 1.3rem;
}

.btn-with-icon .dx-icon {
  margin-right: 5px;
}

.text-decoration-none {
  text-decoration: none !important;
}
.semi-bold {
  font-weight: 500;
}
.margin-0 {
  margin: 0px !important;
}
.widget-container {
  height: 100%;
}

.pies-container {
  margin: auto;
  width: 100%;
  height: 100%;
}

.pies-container > .pie {
  width: 50%;
  float: left;
}

.pr-1 {
  margin-right: 0.25rem !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.card-shadow {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border: none;
}

.swal2-html-container {
  font-size: 1rem !important;
  font-weight: 600 !important;
}
.user-link {
  display: block;
  font-size: 1.25em;
  margin-left: 60px;
  font-weight: 500;
  text-decoration: none;
}

.user-subhead {
  font-size: 0.875em;
  font-style: italic;
  margin-left: 2px;
}

.userInfoTemplate .avatar {
  position: relative;
  max-width: 50px;
  float: left;
  margin-right: 15px;
  border-radius: 50%;
  box-shadow: 1px 1px 4px 0px #d4d4d4;
  width: 50px;
  height: 50px;
}

.avatar-img {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.dropdown-menu {
  padding-bottom: 0px !important;
}
.card-shadow {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border: none;
}

.swal2-html-container {
  font-size: 1rem !important;
  font-weight: 600 !important;
}
.user-link {
  display: block;
  font-size: 1.25em;
  margin-left: 60px;
  font-weight: 500;
  text-decoration: none;
}

.user-subhead {
  font-size: 0.875em;
  font-style: italic;
  margin-left: 2px;
}

.userInfoTemplate .avatar {
  position: relative;
  max-width: 50px;
  float: left;
  margin-right: 15px;
  border-radius: 50%;
  box-shadow: 1px 1px 4px 0px #d4d4d4;
  width: 50px;
  height: 50px;
}

.avatar-img {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.dropdown-menu {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.dx-button {
  border-radius: 5px !important;
}
.settings-card .card-body div {
  border-radius: 1rem;
}
.app-modal .app-form .form-body {
  max-height: 500px;
  overflow: auto !important;
  padding: 0px !important;
}

.app-modal .appFormGroupCard
{
  margin-bottom: 0px !important;
}
.avatar-square, .avatar-square img
{
  border-radius: 0px !important;
}
.page-header
{
  background:white !important;
  border-bottom: 0px !important;
  
}
.page-body
{
  background:white !important;
  padding: 5px !important;
}
.navLink
{
  color: #20a8d8;
  text-decoration: none;
}
.navLink:hover {
  color: #167495;
  text-decoration: underline !important;
  cursor: pointer;
}
.use-my-location-button .dx-button-content
{
  padding: 0.5rem !important;
}
.p-0{
  padding:0px !important;
}
.b-0{
  border:0px !important;
}
.form-label
{
  font-weight: bold;
}

.TripStatus-Scheduled
{
  background:#0d6efd;
}
.TripStatus-Start
{
  background: blueviolet;
}
.TripStatus-Continue
{
  background: #0dcaf0;
}
.TripStatus-Pause
{
  background: orange;
}

.TripStatus-Cancelled
{
  background: red;
}

.TripStatus-Completed
{
  background: #198754;
}