

.app-header {
    position: fixed;
  }
  
  .app-header .dropdown-menu {
    padding: 0;
  }
  
  .app-header .dropdown-menu .dropdown-item {
    padding: 10px 15px;
    border-bottom: 1px solid #e9ecef;
    font-size: 0.9rem;
  }
  
  
  .dropdown-menu[x-placement^=top],
  .dropdown-menu[x-placement^=right],
  .dropdown-menu[x-placement^=bottom],
  .dropdown-menu[x-placement^=left] {
    right: auto;
    bottom: auto;
  }
  
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #767676;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  
  .dropdown-item.active,
  .dropdown-item:active {
    text-decoration: none;
  }
  
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
  }
  
  .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.7875rem;
    color: #6c757d;
    white-space: nowrap;
  }
  
  .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
  }
  .language-selector
  {
    margin-top:5px;
  }
  .language-selector-space
  {
    margin-left: 22px;
  }