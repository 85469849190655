@media only screen and (max-width: 768px) {
    .body
    {
        padding:0px !important;
        margin:0px !important;
    }
    .body .container-fluid
    {
        padding:0px !important;
        margin:0px !important;
    }
    .login-slogan-container,  .grid-title , .dashboard-welcome
    {
        display: none !important;
    }
    .grid-title
    {
        display: none !important;
    }
   
    .user-link 
    {
        font-size: 1em !important;
    }
    .dashboard-card
    {
        margin-bottom: 10px;
    }
}