.button-indicator {
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  .indicators {
    display: flex;
    align-items: center;
  }
.custom-button-icon
{
  font-size: 18px !important;
}
.btn-with-icon-and-text .dx-button-content i{
  margin-right: 5px;
}
.roundedBtn
{
  border-radius: 20px !important;
}
