
body[data-theme='greenmist'] {
    .sidebar {
      --cui-sidebar-bg: #3cbab2 !important;
      --cui-sidebar-nav-link-active-bg: #309992 !important;
      --cui-sidebar-toggler-bg: #f5f5f5 !important;
    }
    --cui-primary-rgb:60, 186, 178 !important; 
    .list-grid {
      background: whitesmoke !important;
    }
  }